<template>
	<div id="loginBody">
		<el-card class="box-card-login">
			<div slot="header" class="clearfix">
				<span>用户登录</span>
				<!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
			</div>
			<el-form ref="form" :model="sizeForm" label-width="40px" size="mini">
				<el-form-item label="账号" size="small">
					<el-input v-model="sizeForm.bm" maxlength=11 style="background-color: rgba(100, 100, 100, 0.3);">
					</el-input>
				</el-form-item>
				<el-form-item label="密码" size="small" ref="passRef">
					<el-input v-model="sizeForm.pass" show-password maxlength=16></el-input>
				</el-form-item>

				<el-form-item size="mini" style="right: 20px;textAlign: end; ">
					<!-- <el-button type="primary" @click="onSubmit">立即创建</el-button>
					<el-button>取消</el-button> -->
					<el-link type="primary" @click="signIn">注册账号</el-link>
				</el-form-item>
			</el-form>
			<el-button
				style="width: 80%;background-color: rgba(100, 100, 100, 0.3);border-color: rgba(100, 100, 100, 0.3);"
				type="primary" @click="login" :loading="ButtonLoading">登录</el-button>
		</el-card>

		<div id="rainBox"></div>
		<!-- <div class="content"></div> -->

	</div>
</template>

<script>
	import jsUtils from '../utils/jsUtils.js'
	import loginService from '../service/login.js'

	export default {
		name: 'login',
		data() {
			return {
				ButtonLoading: false, //登录按钮点击后的加载动画
				//表单属性
				sizeForm: {
					bm: '',
					pass: ''
				},
				loginClick: -1 ,//是否触发登录, -1为触发, 0为不触发
				keydownHandler:null,		//键盘监听事件
				body: null		//文档对象
			}
		},
		components: {},
		created() {
			// console.log("触发created");
			// 取消浏览器自带的右键菜单
			window.oncontextmenu = function(e) {
				//取消默认的浏览器自带右键
				e.preventDefault();
			}
		},
		mounted() {
			// console.log("调用mounted");
			//初始化可视化区域大小
			jsUtils.PassParentInit("loginBody");
			jsUtils.loadingAllIsNone(true, "loadingAll"); //隐藏大遮罩
			// console.log("初始化页面大小完毕");
			// console.log(this.$refs.passRef.$el);
			this.$refs.passRef.$el.style = "margin: 0px";

			// 获取文档对象
			this.body = document.body;
			// 添加键盘按下事件监听器
			this.keydownHandler = (event) => {
			    this.keydown(event);
			};
			this.body.addEventListener('keydown', this.keydownHandler);

			this.$loading().close();

			this.colorInit();
			this.yu();
		},
		methods: {
			//键盘按下事件
			async keydown(event){
				// 例如，检查是否按下的是特定按键
				if (event.key === 'Enter') {
					// console.log('按下了 Enter 键');
					if (this.loginClick === -1)
						await this.login();
				}
			},
			//下雨效果
			yu() {
				const box = document.getElementById('rainBox');
				let boxHeight = box.clientHeight;
				let boxWidth = box.clientWidth;
				// 页面大小发生变化时，改变盒子大小
				window.onresize = function() {
					boxHeight = box.clientHeight;
					boxWidth = box.clientWidth;
				}
				// 每隔一段时间,添加雨滴
				setInterval(() => {
					const rain = document.createElement('div');
					rain.classList.add('rain');
					rain.style.top = 0;
					// 随机刷新雨点位置
					rain.style.left = Math.random() * boxWidth + 'px';
					// 随机雨点透明度
					rain.style.opacity = Math.random();
					box.appendChild(rain);
					// 每隔一段时间,雨水下落
					let race = 1;
					const timer = setInterval(() => {
						// 判断“雨滴”元素的top属性是否超出“盒子”元素的高度来决定是否停止动画
						if (parseInt(rain.style.top) > boxHeight) {
							clearInterval(timer);
							box.removeChild(rain);
						}
						// 每次定时器执行时，“雨滴”元素的top值会逐渐增加，
						//并且增加的速率会随着时间的推移而逐渐加快
						race++;
						rain.style.top = parseInt(rain.style.top) + race + 'px'
					}, 20)
				}, 50);
			},
			//初始化页面颜色样式
			colorInit() {
				let formRef = this.$refs.form.$el.getElementsByTagName("input");
				formRef[0].style = "background-color: rgba(100, 100, 100, 0.3);color:black ;";
				formRef[1].style = "background-color: rgba(100, 100, 100, 0.3);color:black ;";
				// console.log(formRef[1]);
			},
			//登录按钮点击事件
			async login() {
				// console.log("登录点击!");
				this.$loading({
					lock: true,
					text: '加载中',
					//spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				this.loginClick = 0;
				this.ButtonLoading = true;
				let that = this;
				await loginService.login(this.sizeForm.bm, this.sizeForm.pass).then(resp => {
					// console.log("登录返回>>", resp);
					if (resp.code === 200 && resp.data.result !== null && resp.data.user != null) {
						//存储登录凭证
						window.localStorage.clear();
						window.localStorage.setItem("jwt", resp.data.result);
						window.localStorage.setItem("user", JSON.stringify(resp.data.user));
						that.$router.push("/menu");
					} else {
						// console.log("执行结果异常>>", resp.message);
						this.$message({
							message: resp.message,
							type: "warning",
							duration: 2000,
							showClose: true
						});
					}
				}).catch(err => {
					// console.log("登录出错!", err);
					this.$message({
						message: "出现异常情况>>" + err,
						type: "error",
						duration: 2000,
						showClose: true
					});
				}).finally(() => {
					// console.log("必须执行!");
					that.ButtonLoading = false;
					this.loginClick = -1;
					this.$loading().close();
				});
				// console.log("流程完毕!");
			},
			signIn() {
				this.$message({
					message: "暂未开放",
					duration: 2000,
					showClose: true
				});
			}
		},
		beforeDestroy() {
			// console.log("Login实例销毁前",this.keydownHandler);
			this.body.removeEventListener('keydown', this.keydownHandler);
		},
	}
</script>

<style>
	#loginBody {
		position: relative;
		width: 100%;
		background: url('~@/assets/loginBackground.jpg') no-repeat center;
		background-color: rgb(50, 50, 50);
		background-size: 100% 100%;
	}

	#rainBox {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		/* 不阻挡其他元素事件触发 */
		pointer-events: none;
	}

	.rain {
		position: absolute;
		width: 2px;
		height: 50px;
		background: linear-gradient(rgba(255, 255, 255, .3), rgba(255, 255, 255, .6));

	}

	/* 登录卡片区域 */
	.text {
		font-size: 14px;
	}

	/* .item {
		margin-bottom: 18px;
	} */

	.clearfix:before,
	.clearfix:after {
		display: table;
		content: "";
	}

	.clearfix:after {
		clear: both
	}

	.box-card-login {
		width: 480px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background-color: rgba(255, 255, 255, 0.3);
	}

	/* 登录卡片区域 */
</style>
