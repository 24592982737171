<template>
	<div>
		<HeaderMenuComponent></HeaderMenuComponent>
		<!-- 模块的根节点 -->
		<div id="messageTreeNewParentDiv">
			<!-- 模块主体 -->
			<div id="messageTreeNewBody">
				<!-- 顶部的搜索功能区域 -->
				<div id="messageTreeNewTopTitle">
					<div style="width: 250px;display: inline-block;margin: 0px 20px;">
						<el-input placeholder="输入检索内容" v-model="searchInput" class="input-with-select" clearable>
							<el-button slot="append" @click="searchClick" icon="el-icon-search"></el-button>
						</el-input>
					</div>
					<!-- <div id="MessageTraaNewHeaderDivParent" style="display: inline-block;height: 40px;">
						<div class="MessageTraaNewHeaderDiv">abc</div>
						<div class="MessageTraaNewHeaderDiv">def</div>
						<div class="MessageTraaNewHeaderDiv">ghr</div>
					</div> -->
					<!-- 标签选择笔记大类 -->
					<div class="tag-group">
						<!-- <span class="tag-group__title">Plain</span> -->
						<el-tag class="MessageTreeNewHeaderTagChild" v-for="item in Tagitems" :key="item.label"
							:type="item.type" effect="plain">
							{{ item.label }}
						</el-tag>
					</div>
				</div>
				<!-- 数据主体展示 -->
				<div id="messageTreeNewListDiv">
					<!-- 单条数据主体 -->
					<div class="messageTreeNewDataParent" v-for="(mess,index) in messageTreeNewList" :key="mess.id">
						<div class="messageTreeNewTitle" @click="messageTreeNewOnClick(mess)">{{mess.title}}</div>
						<div class="messageTreeNewDescribe" @click="messageTreeNewOnClick(mess)">{{mess.text}}</div>
						<div class="messageTreeNewBottomDiv">
							<div class="messageTreeNewBottomDivLeftDiv">
								<i class="el-icon-search-MessageTreeNewIcon"
									style="width: 16px; height: 16px;"></i><span
									class="messageTreeNewDataSpan1">{{mess.browseNum}}</span>
								<i class="el-icon-thumb_MessageTreeNewIconRight"
									style="width: 16px; height: 16px;"></i><span
									class="messageTreeNewDataSpan2">{{mess.likeNum}}</span>
							</div>
							<div class="messageTreeNewBottomDivRightDiv">
								<span class="messageTreeNewDataSpan3">{{mess.username}}</span>
								<span class="messageTreeNewDataSpan4">{{formatDate(mess.updateTime)}}</span>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>


	</div>
</template>

<script>
	import jsUtils from '../utils/jsUtils.js'
	import http from '../utils/http.js'
	import MessageTreeNew from '../service/MessageTreeNew.js'

	//新的笔记展示标题组件
	export default {
		name: 'messageTreeNew',
		data() {
			return {
				searchInput: '', //笔记检索输入框绑定数据
				messageTreeNewList: [], //笔记摘要信息集合数据
				page: 1, //分页显示数据页码
				dataNum: 100, //分页显示数据每页行数
				Tagitems: [{ //标签属性
						type: 'info',
						label: '标签一'
					},
					{
						type: 'info',
						label: '标签二'
					},
					{
						type: 'info',
						label: '标签三'
					},
					{
						type: 'info',
						label: '标签四'
					},
					{
						type: 'info',
						label: '标签五'
					}
				]
			}
		},
		components: {},
		created() {
			// console.log("触发created");
		},
		async mounted() {
			// console.log("messageTreeNew>>调用mounted");
			//遮罩
			this.$loading({
				lock: true,
				text: '加载中',
				//spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});

			//关闭手动增加的全局遮罩
			jsUtils.loadingAllIsNone(true, "loadingAll");

			//初始化div的大小
			await jsUtils.elementHeightOnDoc('messageTreeNewParentDiv').then(resp => {
				document.getElementById("messageTreeNewParentDiv").style.height = resp + 'px';
			}).catch(err => {
				console.log("设置页面主体可视化高度失败>>", err);
			});
			await jsUtils.elementHeightOnDoc('messageTreeNewBody').then(resp => {
				document.getElementById("messageTreeNewBody").style.height = resp + 'px';
			}).catch(err => {
				console.log("设置页面主体可视化高度失败>>", err);
			});
			await jsUtils.elementHeightOnDoc('messageTreeNewListDiv').then(resp => {
				document.getElementById("messageTreeNewListDiv").style.height = resp - 20 + 'px';
			}).catch(err => {
				console.log("设置页面主体可视化高度失败>>", err);
			});

			await this.selectMessageTreeNew();
			
			// 添加键盘按下事件监听器--搜索绑定
			document.addEventListener('keydown', this.keyEventListener);

			//关闭遮罩
			this.$loading().close();
		},
		methods: {
			// 键盘监听
			keyEventListener(event) {
				// console.log("键盘按下");
			    // 使用 event.keyCode 或 event.key 来获取按下的键的代码或名称
				if (event.keyCode===13){
					// console.log('按键按下:', event.key, event.keyCode);
					this.searchClick();
				}
			},
			//笔记点击的事件
			messageTreeNewOnClick(mess) {
				// console.log('点击了笔记>>', mess);
				let routeUrl = this.$router.resolve({
					path: "/messageShowNew",
					query: {
						id: mess.id
					}
				});
				// console.log("跳转前>>", routeUrl, routeUrl.route);
				window.open(routeUrl.href, '_blank') //关键在此
			},
			//查询笔记摘要数据信息
			async selectMessageTreeNew() {
				await MessageTreeNew.selectMessageTreeNew({
					title: this.searchInput,
					page: this.page,
					dataNum: this.dataNum
				}).then(resp => {
					// console.log("结果>>", resp.data.result);
					if (resp.code === 200) {
						this.messageTreeNewList = resp.data.result;
					}
				}).catch(err => {
					console.log('查询出错>>', err);
				});
			},
			//笔记检索搜索按钮点击事件
			async searchClick() {
				// console.log("搜索按钮点击!", this.searchInput);
				//遮罩
				this.$loading({
					lock: true,
					text: '加载中',
					//spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});

				await this.selectMessageTreeNew();

				this.$loading().close();
			},
			//日期时间类型格式化
			formatDate(date) {
				const myDate = new Date(date);
				const year = myDate.getFullYear();
				const month = String(myDate.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
				const day = String(myDate.getDate()).padStart(2, '0');
				return `${year}-${month}-${day}`;
			}
		},
		beforeDestroy() {
			// console.log("销毁前调用");
			document.removeEventListener('keydown', this.keyEventListener);
		},
		destroyed() {
			// console.log("销毁后调用");
		}
	}
</script>

<style>
	/* 顶部搜索框旁标签子级  */
	.MessageTreeNewHeaderTagChild {
		margin: 0px;
		cursor: pointer;
	}

	.MessageTreeNewHeaderTagChild:hover {
		background-color: #409eff;
		color: white;
	}

	/* 顶部搜索框旁选项卡div父级样式 */
	#MessageTraaNewHeaderDivParent {
		border-style: solid;
		border-width: 0.1px;
		border-color: #000;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
		border-bottom-left-radius: 10px;
	}

	/* 顶部搜索框旁选项卡div子级样式 */
	.MessageTraaNewHeaderDiv {
		border-style: solid;
		border-width: 0px 0.5px 0px 0px;
		border-color: #000;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
		display: inline-block;
		height: 40px;
		padding: 0px 5px;
		margin-right: -0.5px;
		line-height: 40px;
		cursor: pointer;
	}

	.el-icon-thumb_MessageTreeNewIconRight {
		background-image: url('~@/assets/like.png');
		/* 背景图片等比例缩放以填充整个容器 */
		background-size: cover;
		/* 背景图片在容器中的位置 */
		background-position: center;
		position: relative;
		top: 2px;
	}

	.el-icon-search-MessageTreeNewIcon {
		background-image: url('~@/assets/see.png');
		/* 背景图片等比例缩放以填充整个容器 */
		background-size: cover;
		/* 背景图片在容器中的位置 */
		background-position: center;
		position: relative;
		top: 2px;
	}

	/* 主体宽度 */
	#messageTreeNewParentDiv {
		text-align: center;
	}

	#messageTreeNewBody {
		width: 1250px;
		margin: 0 auto;
		background-color: white;
	}

	/* 顶部搜索区域 */
	#messageTreeNewTopTitle {
		padding-top: 5px;
		padding-bottom: 5px;
		display: flex;
		align-items: center;
	}

	/* 数据展示的主体 */
	#messageTreeNewListDiv {
		padding: 10px 20px 10px 20px;
		overflow: auto;
	}

	/* 单条数据主体 */
	.messageTreeNewDataParent {
		/* width: 100%; */
		margin: 0 auto;
		padding: 10px 10px 10px 10px;
		border-bottom: 1px solid black;
	}

	/* 笔记标题 */
	.messageTreeNewTitle {
		text-align: left;
		height: 28px;
		line-height: 28px;
		font-size: 18px;
		color: blue;
	}

	.messageTreeNewTitle:hover {
		cursor: pointer;
	}

	/* 笔记描述 */
	.messageTreeNewDescribe {
		text-align: left;
		color: black;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.messageTreeNewDescribe:hover {
		cursor: pointer;
	}


	.messageTreeNewBottomDiv {
		padding-top: 5px;
	}

	/* 笔记观看, 点赞, 作者, 时间--类样式 */
	.messageTreeNewBottomDivLeftDiv {
		display: inline-block;
		width: 50%;
		text-align: left;
	}

	.messageTreeNewBottomDivRightDiv {
		display: inline-block;
		width: 50%;
		text-align: right;
	}

	.messageTreeNewDataSpan1 {
		display: inline-block;
		font-size: 15px;
		margin-right: 50px;
	}

	.messageTreeNewDataSpan2 {
		font-size: 15px;
	}

	.messageTreeNewDataSpan3 {
		font-size: 15px;
		font-weight: bold;
	}

	.messageTreeNewDataSpan4 {
		font-size: 15px;
		margin-left: 50px;
		font-weight: bold;
	}
</style>
